<template>
  <v-container >
    <div v-show="!isWelcomePageVisible">
      <v-layout pa-2 align-center class="d-flex flex-column">
        <Progress
          :permissionShareData="permissionShareData"
          :welcomeData="welcomeData"
        />
      </v-layout>
    </div>
    <WelcomePage
      v-if="isWelcomePageVisible"
      :welcomeData="welcomeData"
      @hideWelcomePage="hideWelcomePage"
    />
    <InitModal
      v-model="dialog"
      @acceptPermission="acceptPermissionShareData"
      @check="trackbtnCheckDatosPesonales"
    />
  </v-container>
</template>

<script>

export default {
  components: {
    InitModal: () => import('./Agile Flow/init_modal.vue'),
    WelcomePage: () => import('./Agile Flow/welcome_page.vue'),
    Progress: () => import('./Agile Flow/progress/progress.vue'),
  },
  data() {
    return {
      dialog: true,
      isWelcomePageVisible: true,
      welcomeData: { documentNumber: '', selectDocumentType: '', documentConfirmationNumber: '' },
      permissionShareData: false,
    };
  },
  methods: {
    trackbtnCheckDatosPesonales() {
      this.$gtag.event('LP_check_DatosPersonales', {
        event_category: 'LP_check_DatosPersonales',
        event_label: 'LP_check_DatosPersonales',
        value: 1,
      });
    },
    trackbtnIniciarProceso() {
      this.$gtag.event('LP_btn_IniciarProceso', {
        event_category: 'LP_btn_IniciarProceso',
        event_label: 'LP_btn_IniciarProceso',
        value: 1,
      });
    },
    hideWelcomePage() {
      this.isWelcomePageVisible = false;
    },
    acceptPermissionShareData() {
      this.permissionShareData = true;
      this.trackbtnIniciarProceso();
    },
  },
};
</script>
